import { Palette } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-pro'

import { UserPostFragmentFragment, User_Post_Status_Enum } from 'api/generated'

export const getUserPostStatusColorPalette = (
  status: string,
  palette: Palette
) => {
  switch (status) {
    case User_Post_Status_Enum.Accepted:
      return palette.info.light

    case User_Post_Status_Enum.Denied:
      return palette.error.light

    case User_Post_Status_Enum.Pending:
      return palette.warning.light

    default:
      return ''
  }
}

export const getUserPostCellClassName = (params: GridCellParams): string => {
  const row = params?.row as UserPostFragmentFragment

  switch (params.field) {
    case 'status': {
      const status = row?.status
      switch (status) {
        case User_Post_Status_Enum.Pending:
          return 'warning'

        case User_Post_Status_Enum.Denied:
          return 'error'

        case User_Post_Status_Enum.Accepted:
          return 'info'

        default:
          return ''
      }
    }

    default:
      return ''
  }
}

export const getDisableAcceptUserPost = (status?: string) =>
  [User_Post_Status_Enum.Accepted].includes(status as User_Post_Status_Enum)

export const getDisableDenyUserPost = (status?: string) =>
  [User_Post_Status_Enum.Denied].includes(status as User_Post_Status_Enum)

export default {
  getUserPostStatusColorPalette,
  getUserPostCellClassName
}
