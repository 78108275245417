import React from 'react'

import UserNotificationFormDialog from '../UserNotificationFormDialog'
import {
  Sender_Method,
  Notification_Type,
  useSendNotificationMutation
} from 'api/generated'

export type SendUserNotificationFormDialogProps = {
  tableName?: string
  rowId?: string
  openDialog: boolean
  onCancel: () => void
  id: string | undefined
  type?: Notification_Type
}

const SendUserNotificationFormDialog: React.FC<
  SendUserNotificationFormDialogProps
> = ({
  openDialog,
  onCancel,
  tableName,
  rowId,
  id,
  type = Notification_Type.User
}: SendUserNotificationFormDialogProps) => {
  const [sendNotification, { loading, error }] = useSendNotificationMutation()

  return (
    <UserNotificationFormDialog
      loading={loading}
      error={error}
      openDialog={openDialog}
      onCancel={onCancel}
      onSubmit={(data, onCompletedCallback) => {
        const { senderMethod, message, subject, group, ...metadata } = data

        sendNotification({
          variables: {
            id,
            senderMethod: senderMethod as Sender_Method,
            group,
            type,
            message,
            subject,
            tableName,
            rowId,
            data: metadata
          },
          onCompleted: data => {
            if (data?.userNotification) {
              onCompletedCallback?.()
            }
          }
        })
      }}
    />
  )
}

export default SendUserNotificationFormDialog
