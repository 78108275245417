import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryHookOptions } from '@apollo/client'

import MuiDataGrid from '../MuiDataGrid'
import QueryError from '../QueryError'
import MuiLink from '../MuiLink'
import {
  GetUserPostLikesQuery,
  GetUserPostLikesQueryVariables,
  UserPostCommentFragmentFragment,
  useGetUserPostLikesQuery
} from 'api/generated'
import { useThemeType } from 'context/theme'
import { dataGridDateTimeFormatter } from 'utils/date'
import { getFilterPath, valueFormatter } from 'utils/dataGrid'
import { toString } from 'utils/string'

type UserPostLikesTableProps = {
  queryOptions?: QueryHookOptions<
    GetUserPostLikesQuery,
    GetUserPostLikesQueryVariables
  >
}

const UserPostLikesTable: React.FC<UserPostLikesTableProps> = ({
  queryOptions
}: UserPostLikesTableProps) => {
  const { t } = useTranslation()
  const { data, loading, error, refetch } =
    useGetUserPostLikesQuery(queryOptions)
  const {
    mediaQueries: { mdDown }
  } = useThemeType()

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <>
      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : (
        <MuiDataGrid
          rows={data?.userPostLikes || []}
          loading={loading && !data}
          Toolbar={null}
          NoRowsOverlay={null}
          height='100%'
          disableColumnMenu={true}
          hideFooter={true}
          density='compact'
          initialState={{
            pinnedColumns: mdDown ? undefined : { right: ['actions'] }
          }}
          columns={[
            {
              field: 'id',
              headerName: `${t('id')}`,
              flex: 1
            },
            {
              field: 'user.first_name',
              headerName: `${t('tenant')}`,
              flex: 1,
              renderCell: params => {
                const userPostComment =
                  params?.row as UserPostCommentFragmentFragment
                const user = userPostComment?.user
                const filterByIdPath = getFilterPath('users', user?.id)

                return (
                  <MuiLink to={filterByIdPath}>{`${toString(
                    user?.first_name
                  )} ${toString(user?.last_name)}`}</MuiLink>
                )
              },
              valueFormatter: (_value, row) =>
                valueFormatter<UserPostCommentFragmentFragment>({
                  row,
                  key: 'user.first_name'
                })
            },
            {
              field: 'deleted_at',
              headerName: `${t('deletedAt')}`,
              flex: 1,
              type: 'dateTime',
              valueFormatter: dataGridDateTimeFormatter
            },
            {
              field: 'created_at',
              headerName: `${t('createdAt')}`,
              flex: 1,
              type: 'dateTime',
              valueFormatter: dataGridDateTimeFormatter
            }
          ]}
        />
      )}
    </>
  )
}

export default UserPostLikesTable
