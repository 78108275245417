import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  InputAdornment,
  IconButton,
  Menu,
  Tooltip
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Send as SendIcon,
  Close as CloseIcon,
  AddLink as AddLinkIcon
} from '@mui/icons-material'
import { ApolloError } from '@apollo/client'

import MuiButton from '../MuiButton'
import MuiTextField from '../MuiTextField'
import MuiSnackbar from '../MuiSnackbar'
import {
  SendUserNotificationSchemaType,
  sendUserNotificationSchema,
  defaultSendUserNotificationValues
} from 'schemas/userNotification'
import { Sender_Type_Enum, Notification_Group_Enum } from 'api/generated'
import { LINKS } from 'utils/link'

export type UserNotificationFormDialogProps = {
  onCancel: () => void
  onSubmit: (
    data: SendUserNotificationSchemaType,
    onCompletedCallback?: () => void
  ) => void
  openDialog: boolean
  loading: boolean
  error?: ApolloError
}

const UserNotificationFormDialog: React.FC<UserNotificationFormDialogProps> = ({
  onCancel,
  onSubmit,
  openDialog,
  loading,
  error
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { control, handleSubmit, reset, watch } =
    useForm<SendUserNotificationSchemaType>({
      resolver: yupResolver(sendUserNotificationSchema),
      defaultValues: defaultSendUserNotificationValues
    })
  const senderMethod = watch('senderMethod')
  const subjectLabel =
    senderMethod === Sender_Type_Enum.Email ? 'subject' : 'title'

  const handleOnClose = useCallback(() => {
    onCancel()
    reset()
  }, [onCancel, reset])

  const handleOnSubmit = handleSubmit(data => {
    onSubmit(data, () => {
      handleOnClose()
    })
  })

  const handleCloseLinksMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenLinksMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  return (
    <>
      <MuiSnackbar
        snackbarProps={{
          open: !!error
        }}
        alertProps={{
          severity: 'error'
        }}
        message={error?.message}
      />

      <Dialog
        open={openDialog}
        PaperProps={{
          component: 'form',
          onSubmit: handleOnSubmit
        }}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>{t('sendNotification')}</DialogTitle>
        <DialogContent>
          <Controller
            name='senderMethod'
            control={control}
            defaultValue=''
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error }
            }) => {
              return (
                <MuiTextField
                  label={t('senderMethod')}
                  error={!!error?.message}
                  helperText={error?.message && t(error?.message)}
                  inputRef={ref}
                  select
                  {...fieldProps}
                >
                  {Object.values(Sender_Type_Enum)?.map(senderType => {
                    return (
                      <MenuItem key={senderType} value={senderType}>
                        {t(senderType)}
                      </MenuItem>
                    )
                  })}
                </MuiTextField>
              )
            }}
          />
          <Controller
            name='group'
            control={control}
            defaultValue=''
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error }
            }) => {
              return (
                <MuiTextField
                  label={t('group')}
                  error={!!error?.message}
                  helperText={error?.message && t(error?.message)}
                  inputRef={ref}
                  select
                  {...fieldProps}
                >
                  {Object.values(Notification_Group_Enum)?.map(
                    notificationGroup => {
                      return (
                        <MenuItem
                          key={notificationGroup}
                          value={notificationGroup}
                        >
                          {t(notificationGroup)}
                        </MenuItem>
                      )
                    }
                  )}
                </MuiTextField>
              )
            }}
          />
          <Controller
            name='subject'
            control={control}
            defaultValue=''
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error }
            }) => {
              return (
                <MuiTextField
                  label={t(subjectLabel)}
                  error={!!error?.message}
                  helperText={error?.message && t(error?.message)}
                  inputRef={ref}
                  {...fieldProps}
                />
              )
            }}
          />

          <Controller
            name='message'
            control={control}
            defaultValue=''
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error }
            }) => {
              return (
                <MuiTextField
                  label={t('message')}
                  error={!!error?.message}
                  helperText={error?.message && t(error?.message)}
                  multiline
                  inputRef={ref}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip title={t('addLink')}>
                          <IconButton
                            aria-label='add link'
                            onClick={handleOpenLinksMenu}
                            edge='end'
                          >
                            <AddLinkIcon />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          id='link-menu'
                          anchorEl={anchorEl}
                          open={!!anchorEl}
                          onClose={handleCloseLinksMenu}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          slotProps={{
                            paper: {
                              style: {
                                maxHeight: 130
                              }
                            }
                          }}
                        >
                          {LINKS.map(link => (
                            <MenuItem
                              key={link?.value}
                              onClick={() => {
                                fieldProps?.onChange(
                                  fieldProps?.value?.concat(link?.value)
                                )
                                handleCloseLinksMenu()
                              }}
                            >
                              {t(link?.label)}
                            </MenuItem>
                          ))}
                        </Menu>
                      </InputAdornment>
                    )
                  }}
                  {...fieldProps}
                />
              )
            }}
          />

          <Controller
            name='link'
            control={control}
            defaultValue=''
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error }
            }) => {
              return (
                <MuiTextField
                  label={t('link')}
                  error={!!error?.message}
                  helperText={error?.message && t(error?.message)}
                  inputRef={ref}
                  select
                  {...fieldProps}
                >
                  <MenuItem value=''>{t('none')}</MenuItem>

                  {LINKS.map(link => {
                    return (
                      <MenuItem key={link?.value} value={link?.value}>
                        {t(link?.label)}
                      </MenuItem>
                    )
                  })}
                </MuiTextField>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={handleOnClose}
            startIcon={<CloseIcon />}
            disabled={loading}
          >
            {t('cancel')}
          </MuiButton>
          <MuiButton type='submit' startIcon={<SendIcon />} disabled={loading}>
            {t('submit')}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserNotificationFormDialog
