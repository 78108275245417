import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryHookOptions } from '@apollo/client'
import { Tooltip } from '@mui/material'
import {
  Restore as RestoreIcon,
  Delete as DeleteIcon
} from '@mui/icons-material'

import MuiDataGrid from '../MuiDataGrid'
import QueryError from '../QueryError'
import MuiLink from '../MuiLink'
import ConfirmationDialog from '../ConfirmationDialog'
import {
  GetUserPostCommentsQuery,
  GetUserPostCommentsQueryVariables,
  UserPostCommentFragmentFragment,
  useGetUserPostCommentsQuery,
  useUpdateUserPostCommentMutation
} from 'api/generated'
import { useThemeType } from 'context/theme'
import { dataGridDateTimeFormatter } from 'utils/date'
import { getFilterPath, valueFormatter } from 'utils/dataGrid'
import { toString } from 'utils/string'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

type UserPostCommentsTableProps = {
  queryOptions?: QueryHookOptions<
    GetUserPostCommentsQuery,
    GetUserPostCommentsQueryVariables
  >
}

export enum ROW_ACTIONS {
  DELETE = 'DELETE',
  RESTORE = 'RESTORE'
}

const UserPostCommentsTable: React.FC<UserPostCommentsTableProps> = ({
  queryOptions
}: UserPostCommentsTableProps) => {
  const { t } = useTranslation()
  const {
    mediaQueries: { mdDown }
  } = useThemeType()
  const [selectedRow, setSelectedRow] = useState<{
    row?: UserPostCommentFragmentFragment
    action?: ROW_ACTIONS
  }>()
  const { data, loading, error, refetch } =
    useGetUserPostCommentsQuery(queryOptions)
  const [
    updateUserPostCommentMutation,
    { loading: mutationLoading, error: mutationError }
  ] = useUpdateUserPostCommentMutation({
    onCompleted: data => {
      if (data?.userPostComment?.id) {
        refetch()
        setSelectedRow(undefined)
      }
    }
  })

  const handleSelectRow = useCallback(
    (row: UserPostCommentFragmentFragment, action: ROW_ACTIONS) => {
      setSelectedRow({ row, action })
    },
    []
  )

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  const handleCancelDialog = useCallback(() => {
    setSelectedRow(undefined)
  }, [])

  const handleDeleteOrRestoreRow = useCallback(() => {
    if (selectedRow) {
      updateUserPostCommentMutation({
        variables: {
          id: selectedRow?.row?.id,
          _set: {
            deleted_at:
              selectedRow?.action === ROW_ACTIONS.DELETE ? 'now()' : null
          }
        }
      })
    }
  }, [selectedRow, updateUserPostCommentMutation])

  return (
    <>
      <ConfirmationDialog
        openDialog={!!selectedRow}
        title={
          selectedRow
            ? selectedRow?.action === ROW_ACTIONS.DELETE
              ? t('delete')
              : t('restore')
            : ''
        }
        description={
          selectedRow
            ? selectedRow?.action === ROW_ACTIONS.DELETE
              ? t('deleteRowConfirmationMessage', {
                  rowName: t('userPostComment')
                })
              : t('restoreRowConfirmationMessage', {
                  rowName: t('userPostComment')
                })
            : ''
        }
        onCancel={handleCancelDialog}
        onAccept={handleDeleteOrRestoreRow}
        loading={mutationLoading}
        error={mutationError}
      />

      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : (
        <MuiDataGrid
          rows={data?.userPostComments || []}
          loading={loading && !data}
          Toolbar={null}
          NoRowsOverlay={null}
          height='100%'
          disableColumnMenu={true}
          density='compact'
          initialState={{
            pinnedColumns: mdDown ? undefined : { right: ['actions'] }
          }}
          columns={[
            {
              field: 'id',
              headerName: `${t('id')}`,
              flex: 1,
              renderCell: params => (
                <MuiLink to={`/user-post-comments/${params?.value}`}>
                  {params?.value}
                </MuiLink>
              )
            },
            {
              field: 'content',
              headerName: `${t('content')}`,
              flex: 1
            },
            {
              field: 'user.first_name',
              headerName: `${t('tenant')}`,
              flex: 1,
              renderCell: params => {
                const userPostComment =
                  params?.row as UserPostCommentFragmentFragment
                const user = userPostComment?.user
                const filterByIdPath = getFilterPath('users', user?.id)

                return (
                  <MuiLink to={filterByIdPath}>{`${toString(
                    user?.first_name
                  )} ${toString(user?.last_name)}`}</MuiLink>
                )
              },
              valueFormatter: (_value, row) =>
                valueFormatter<UserPostCommentFragmentFragment>({
                  row,
                  key: 'user.first_name'
                })
            },
            {
              field: 'deleted_at',
              headerName: `${t('deletedAt')}`,
              flex: 1,
              type: 'dateTime',
              valueFormatter: dataGridDateTimeFormatter
            },
            {
              field: 'created_at',
              headerName: `${t('createdAt')}`,
              flex: 1,
              type: 'dateTime',
              valueFormatter: dataGridDateTimeFormatter
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: `${t('actions')}`,
              cellClassName: 'actions',
              flex: 1,
              getActions: ({ row }) => {
                const device = row as UserPostCommentFragmentFragment

                return [
                  device?.deleted_at ? (
                    <GridActionsCellItem
                      key='restore'
                      icon={
                        <Tooltip title={t('restore')}>
                          <RestoreIcon />
                        </Tooltip>
                      }
                      label={t('restore')}
                      className='textPrimary'
                      onClick={() =>
                        handleSelectRow?.(device, ROW_ACTIONS.RESTORE)
                      }
                      color='inherit'
                    />
                  ) : (
                    <GridActionsCellItem
                      key='delete'
                      icon={
                        <Tooltip title={t('delete')}>
                          <DeleteIcon />
                        </Tooltip>
                      }
                      label={t('delete')}
                      className='textPrimary'
                      onClick={() =>
                        handleSelectRow?.(device, ROW_ACTIONS.DELETE)
                      }
                      color='inherit'
                    />
                  )
                ]
              }
            }
          ]}
        />
      )}
    </>
  )
}

export default UserPostCommentsTable
