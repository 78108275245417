import { linkConfig } from 'config'

const baseUrl = linkConfig?.baseUrl

export const LINKS = [
  {
    label: 'intercomHome',
    value: `${baseUrl}/intercom/HOME`
  },
  {
    label: 'intercomMessages',
    value: `${baseUrl}/intercom/MESSAGES`
  },
  {
    label: 'intercomHelpCenter',
    value: `${baseUrl}/intercom/HELP_CENTER`
  },
  {
    label: 'intercomTickets',
    value: `${baseUrl}/intercom/TICKETS`
  },
  {
    label: 'communityScreen',
    value: `${baseUrl}/community`
  },
  {
    label: 'devicesScreen',
    value: `${baseUrl}/devices`
  },
  {
    label: 'settingsScreen',
    value: `${baseUrl}/settings`
  },
  {
    label: 'profileScreen',
    value: `${baseUrl}/profile`
  },
  {
    label: 'changePasswordScreen',
    value: `${baseUrl}/change-password`
  },
  {
    label: 'addPhoneNumberScreen',
    value: `${baseUrl}/add-phone-number`
  },
  {
    label: 'changePhoneNumberScreen',
    value: `${baseUrl}/change-phone-number`
  },
  {
    label: 'changeEmailScreen',
    value: `${baseUrl}/change-email`
  },
  {
    label: 'addEmailScreen',
    value: `${baseUrl}/link-email`
  },
  {
    label: 'applicationFormScreen',
    value: `${baseUrl}/application-form`
  },
  {
    label: 'requestToRentScreen',
    value: `${baseUrl}/request-to-rent`
  },
  {
    label: 'doorLocksModal',
    value: `${baseUrl}/door-locks`
  },
  {
    label: 'scenesScreen',
    value: `${baseUrl}/scenes`
  },
  {
    label: 'domoAssistScreen',
    value: `${baseUrl}/assist`
  },
  {
    label: 'myDocumentsScreen',
    value: `${baseUrl}/my-documents`
  },
  {
    label: 'termsAndConditionsScreen',
    value: `${baseUrl}/terms-and-conditions`
  },
  {
    label: 'aboutDomoScreen',
    value: `${baseUrl}/about`
  },
  {
    label: 'setupInternetScreen',
    value: `${baseUrl}/setup-internet`
  },
  {
    label: 'checklistScreen',
    value: `${baseUrl}/checklist`
  },
  {
    label: 'getOffTheGridScreen',
    value: `${baseUrl}/get-off-the-grid`
  },
  {
    label: 'notificationsScreen',
    value: `${baseUrl}/notifications`
  },
  {
    label: 'notificationSettingsScreen',
    value: `${baseUrl}/notification-settings`
  },
  {
    label: 'leavingReviewScreen',
    value: `${baseUrl}/leaving-review`
  },
  {
    label: 'leavingReviewsScreen',
    value: `${baseUrl}/leaving-reviews`
  }
]

export default {
  LINKS
}
