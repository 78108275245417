import React from 'react'
import { Stack, Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import UserPostCommentsTable from '../UserPostCommentsTable'
import UserPostLikesTable from '../UserPostLikesTable'
import { Order_By, UserPostFragmentFragment } from 'api/generated'

type UserPostsDetailPanelProps = {
  data: UserPostFragmentFragment | undefined | null
}

const UserPostsDetailPanel: React.FC<UserPostsDetailPanelProps> = ({
  data
}: UserPostsDetailPanelProps) => {
  const { t } = useTranslation()
  const postId = data?.id

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack
          direction='row'
          spacing={2}
          p={2}
          alignContent='center'
          justifyContent='center'
          alignItems='center'
          flex={1}
          height='100%'
        >
          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('comments')}</Typography>
            <Box height={200}>
              <UserPostCommentsTable
                queryOptions={{
                  variables: {
                    where: {
                      user_post_id: {
                        _eq: postId
                      }
                    },
                    order_by: {
                      created_at: Order_By.Desc
                    }
                  },
                  skip: !postId
                }}
              />
            </Box>
          </Stack>

          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('likes')}</Typography>
            <Box height={200}>
              <UserPostLikesTable
                queryOptions={{
                  variables: {
                    where: {
                      user_post_id: {
                        _eq: postId
                      }
                    },
                    order_by: {
                      created_at: Order_By.Desc
                    }
                  },
                  skip: !postId
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default UserPostsDetailPanel
