import React from 'react'
import { Stack, Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import UserPostLikesTable from '../UserPostLikesTable'
import { Order_By, UserPostCommentFragmentFragment } from 'api/generated'

type UserPostCommentsDetailPanelProps = {
  data: UserPostCommentFragmentFragment | undefined | null
}

const UserPostCommentsDetailPanel: React.FC<
  UserPostCommentsDetailPanelProps
> = ({ data }: UserPostCommentsDetailPanelProps) => {
  const { t } = useTranslation()
  const postCommentId = data?.id

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack
          direction='row'
          spacing={2}
          p={2}
          alignContent='center'
          justifyContent='center'
          alignItems='center'
          flex={1}
          height='100%'
        >
          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('likes')}</Typography>
            <Box height={200}>
              <UserPostLikesTable
                queryOptions={{
                  variables: {
                    where: {
                      user_post_comment_id: {
                        _eq: postCommentId
                      }
                    },
                    order_by: {
                      created_at: Order_By.Desc
                    }
                  },
                  skip: !postCommentId
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default UserPostCommentsDetailPanel
