import React from 'react'

import CommentListForm from '../CommentListForm'
import {
  GetBuildingTourCommentsDocument,
  GetBuildingTourCommentsQuery,
  Order_By,
  useGetBuildingTourCommentsQuery,
  useInsertBuildingTourCommentMutation
} from 'api/generated'
import { useUser } from 'context/auth/user'

export type BuildingTourCommentListFormProps = {
  id?: string
}

const BuildingTourCommentListForm: React.FC<
  BuildingTourCommentListFormProps
> = ({ id }: BuildingTourCommentListFormProps) => {
  const { dbUser } = useUser()
  const queryVariables = {
    where: {
      building_tour_id: {
        _eq: id
      }
    },
    order_by: { created_at: Order_By.Desc }
  }
  const { data, loading, error, refetch } = useGetBuildingTourCommentsQuery({
    variables: queryVariables
  })
  const [
    insertBuildingTourComment,
    { loading: mutationLoading, error: mutationError }
  ] = useInsertBuildingTourCommentMutation({
    update: (cache, { data }) => {
      const buildingTourComment = data?.buildingTourComment

      if (buildingTourComment) {
        const existingData = cache.readQuery({
          query: GetBuildingTourCommentsDocument,
          variables: queryVariables
        }) as GetBuildingTourCommentsQuery

        const buildingTourCommentsAggregate =
          existingData?.buildingTourCommentsAggregate
        const buildingTourComments = existingData?.buildingTourComments

        cache.writeQuery({
          query: GetBuildingTourCommentsDocument,
          variables: queryVariables,
          data: {
            ...existingData,
            buildingTourCommentsAggregate,
            buildingTourComments: [buildingTourComment, ...buildingTourComments]
          }
        })
      }
    }
  })

  return (
    <CommentListForm
      data={data?.buildingTourComments?.map(comment => {
        return {
          id: comment?.id,
          user: comment?.user,
          comment: comment?.comment,
          createdAt: comment?.created_at as string,
          files: comment?.files
        }
      })}
      loading={loading && !data}
      error={error}
      refetch={refetch}
      onSubmit={(data, onCompletedCallback) => {
        insertBuildingTourComment({
          variables: {
            object: {
              building_tour_id: id,
              user_id: dbUser?.id,
              comment: data?.comment,
              files: {
                data: data?.files?.map(file => {
                  return {
                    path: file?.value,
                    folder: 'building-tour'
                  }
                })
              }
            }
          },
          onCompleted: data => {
            if (data?.buildingTourComment) {
              onCompletedCallback?.()
            }
          }
        })
      }}
      mutationLoading={mutationLoading}
      mutationError={mutationError}
    />
  )
}

export default BuildingTourCommentListForm
