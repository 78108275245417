import {
  object,
  stringRequired,
  string,
  uuidRequired,
  dateRequired
} from '../common'

export type SendUserNotificationSchemaType = {
  senderMethod: string
  message: string
  subject: string | undefined
  link: string | undefined
  group: string
}

export const sendUserNotificationSchema = object.shape({
  senderMethod: stringRequired,
  message: stringRequired,
  subject: string,
  link: string,
  group: stringRequired
})

export const defaultSendUserNotificationValues: SendUserNotificationSchemaType =
  {
    senderMethod: '',
    message: '',
    subject: '',
    link: '',
    group: ''
  }

export type UserNotificationSchemaType = {
  id: string
  message: string
  subject: string
  senderType: string
  senderResponse: object
  group: string
  from: string
  to: string
  tableName: string
  rowId: string
  user: string
  link: string
  senderUser: string
  createdAt: Date
}

export const userNotificationSchema = object.shape({
  id: uuidRequired,
  message: stringRequired,
  subject: stringRequired,
  senderType: stringRequired,
  senderResponse: object,
  group: stringRequired,
  from: stringRequired,
  to: stringRequired,
  tableName: stringRequired,
  rowId: stringRequired,
  user: stringRequired,
  link: stringRequired,
  senderUser: stringRequired,
  createdAt: dateRequired
})

export const defaultUserNotificationValues: UserNotificationSchemaType = {
  id: '',
  message: '',
  subject: '',
  senderType: '',
  senderResponse: {},
  group: '',
  from: '',
  to: '',
  tableName: '',
  rowId: '',
  user: '',
  link: '',
  senderUser: '',
  createdAt: new Date()
}
